
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";


// Bootstrap
// @import '../../../public/vendor/bootstrap/css/bootstrap';
// @import '~bootstrap/scss/bootstrap';
@import "_bootstrap";
@import "mixins";
@import "global";
@import "navbar";
@import "masthead";
@import "services";
@import "portfolio";
@import "bootstrap-overrides";
@import "cards-gallery";
@import "donation";

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

// Admin

ul.pagination li {
  margin-left: 5px;
  font-weight: bold;
}

td.three-lines {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  word-wrap: break-word;
  max-height: 6.5em;
  line-height: 1.8em;
}

// Frontend

#donationAccordion {
  .card-header {
    cursor: pointer;
  }
  .collapse, .collapsing {
    background-color: #fff;
    color: rgb(33, 37, 41);
    form label {
      float: left;
    }
  }
}

#faqAccordation {
  .card-header {
    cursor: pointer;
  }
  li.list-group-item {
    li {
      text-align: left;
    }
  }
}

form span.help-block {
  font-style: italic;
  color: #555;
}

.btn-share {
  background-color: #3a5897;
  color: #ffffff;
  span {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-left: 5px;
    line-height: 12px;
  }
  &:hover {
    background-color: #555555;
    color: #ffffff;
  }
}

// Gallery
.carousel-caption {
  text-shadow:
          2px 2px 0 #000,
          -1px -1px 0 #000,
          1px -1px 0 #000,
          -1px 1px 0 #000,
          1px 1px 0 #000;
  position: static;
}

// Modals
.modal-header button.close {
  cursor: pointer;
  float: right;
  text-shadow: 0 1px 0 #fff;
}