#donation {
  #donationContainer {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) calc(0.25rem - 1px) calc(0.25rem - 1px);
    #donationTab {
      li.nav-item {
        width: 15%;
        background-color: #888888;
        border-radius: 5px 5px 0 0;
        border: 1px solid #555555;
        border-bottom: 0;
        .nav-link {
          color: #ffffff;
        }
        .nav-link.active {
          color: #495057;
        }
      }
    }
    #donationTabContent {
      border-left: 1px solid #555555;
      border-right: 1px solid #555555;
      border-bottom: 1px solid #555555;
      border-radius: 0 0 5px 5px;
      background-color: #f5f8fa;
      .tab-pane {
        background-color: #f5f8fa;
      }
    }
  }
}